/* Invert navigation bars to fill style */
:root,
:root.theme-dark,
:root .theme-dark {
  --f7-bars-bg-color: var(--f7-theme-color);
  --f7-bars-bg-color-rgb: var(--f7-theme-color-rgb);
  --f7-bars-translucent-opacity: 0.9;
  --f7-bars-text-color: #fff;
  --f7-bars-link-color: #fff;
  --f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
  --f7-bars-border-color: transparent;
  --f7-tabbar-link-active-color: #fff;
  --f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
  --f7-sheet-border-color: transparent;
  --f7-tabbar-link-active-border-color: #fff;
}
.appbar,
.navbar,
.toolbar,
.subnavbar,
.calendar-header,
.calendar-footer {
  --f7-touch-ripple-color: var(--f7-touch-ripple-white);
  --f7-link-highlight-color: var(--f7-link-highlight-white);
  --f7-button-text-color: #fff;
  --f7-button-pressed-bg-color: rgba(255,255,255,0.1);
}
.navbar-large-transparent,
.navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #000;

  --r: 0;
  --g: 122;
  --b: 255;
  --progress: var(--f7-navbar-large-collapse-progress);
  --f7-bars-link-color: rgb(
    calc(var(--r) + (255 - var(--r)) * var(--progress)),
    calc(var(--g) + (255 - var(--g)) * var(--progress)),
    calc(var(--b) + (255 - var(--b)) * var(--progress))
  );
}
.theme-dark .navbar-large-transparent,
.theme-dark .navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #fff;
}


@keyframes pulse-red {
  0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.calling {
  transform: scale(1);
  animation: pulse-red 2s infinite;
}


/* Your app custom styles here */
.dbheading {
  text-align:center
}

.ctr {
  text-align:center;
}

#popup-table .page-content {
  height: 100%;
}

.circle-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
 }
 .circle-profile img {
      min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
 }

 .avatar-big {
  background-image: url('https://img.imageboss.me/justdrinks/width/600/avatar_circles.png');
  background-repeat: no-repeat;
}
.avatar-small {
  background-image: url('https://img.imageboss.me/justdrinks/width/300/avatar_circles.png');
  background-repeat: no-repeat;
}
.avatarsmall1 {
  height: 100px;
  width: 100px;
  background-position: 0px 0px;
}
.avatarsmall2 {
  height: 100px;
  width:100px;
  background-position: -100px 0px;
}
.avatarsmall3 {
  height: 100px;
  width:100px;
  background-position: -200px 0px;
}

.avatarsmall4 {
  height: 100px;
  width:100px;
  background-position: 0px -100px;
}
.avatarsmall5 {
  height: 100px;
  width:100px;
  background-position: -100px -100px;
}
.avatarsmall6 {
  height: 100px;
  width:100px;
  background-position: -200px -100px;
}

.avatarsmall7 {
  height: 100px;
  width:100px;
  background-position: 0px -200px;
}
.avatarsmall8 {
  height: 100px;
  width:100px;
  background-position: -100px -200px;
}
.avatarsmall9 {
  height: 100px;
  width:100px;
  background-position: -200px -200px;
}


.avatarbig1 {
  height: 200px;
  width:200px;
  background-position: 0px 0px;
}
.avatarbig2 {
  height: 200px;
  width:200px;
  background-position: -200px 0px;
}
.avatarbig3 {
  height: 200px;
  width:200px;
  background-position: -400px 0px;
}
.avatarbig4 {
  height: 200px;
  width:200px;
  background-position: 0px -200px;
}
.avatarbig5 {
  height: 200px;
  width:200px;
  background-position: -200px -200px;
}
.avatarbig6 {
  height: 200px;
  width:200px;
  background-position: -400px -200px;
}
.avatarbig7 {
  height: 200px;
  width:200px;
  background-position: 0px -400px;
}
.avatarbig8 {
  height: 200px;
  width:200px;
  background-position: -200px -400px;
}
.avatarbig9 {
  height: 200px;
  width:200px;
  background-position: -400px -400px;
}
